import React from 'react';
import { ERROR } from '../../colors';
import { ProfitRoverPrimaryButton } from '../forms/ProfitRoverButtons';
import { ProfitRoverCard } from '../generic/ProfitRoverCard';
import { useEditPreferencesMutation, USER_PREFERENCES_SUBJECT } from '../../data-access/query/preferences';

const CommunicationPreferences = ({ data, locations }) => {
  const [overallEmailReport, setOverallEmailReport] = React.useState(data.kp_insights_weekly ?? false);
  const [locationEmailReport, setLocationEmailReport] = React.useState(data.kp_insights_weekly_location ?? false);
  const [isDirty, setIsDirty] = React.useState(false);

  const userId = React.useRef(localStorage.getItem('id')).current;
  const hasMultipleActiveLocations =
    locations.filter(location => {
      const subscriptions = location.status_by_subscription_id;
      return subscriptions && Object.values(subscriptions).some(subscription => subscription.status === 'ACTIVE');
    }).length > 1;

  const { mutate: editEmailPreferences, isLoading: isLoadingUpdate, error } = useEditPreferencesMutation(
    userId,
    USER_PREFERENCES_SUBJECT.EMAIL,
    {
      onSuccess: () => setIsDirty(false),
    },
  );

  const onOverallChange = e => {
    setOverallEmailReport(e.target.checked);
    setIsDirty(true);
  };

  const onLocationChange = e => {
    setLocationEmailReport(e.target.checked);
    setIsDirty(true);
  };

  const saveChanges = () => {
    editEmailPreferences({
      kp_insights_weekly: overallEmailReport,
      kp_insights_weekly_location: locationEmailReport,
    });
  };

  return (
    <ProfitRoverCard className="settings-card">
      <h4 className="settings-subheader">Communication</h4>
      {hasMultipleActiveLocations ? (
        <div className="settings-container">
          <h5 className="label">Send me weekly summary emails for:</h5>
          <div className="checkbox-container">
            <input
              type="checkbox"
              name="overall_weekly_updates"
              checked={overallEmailReport}
              onChange={onOverallChange}
              disabled={isLoadingUpdate}
            />
            <span className="ml-2">My Overall Business (1 email)</span>
          </div>
          <div className="checkbox-container">
            <input
              type="checkbox"
              name="location_weekly_updates"
              checked={locationEmailReport}
              onChange={onLocationChange}
              disabled={isLoadingUpdate}
            />
            <span className="ml-2">Each of My Locations (1 email per location)</span>
          </div>
        </div>
      ) : (
        <div className="settings-container">
          <div className="checkbox-container">
            <input
              type="checkbox"
              name="weekly_updates"
              checked={overallEmailReport}
              onChange={onOverallChange}
              disabled={isLoadingUpdate}
            />
            <span className="ml-2">Send me weekly summary emails about my business</span>
          </div>
        </div>
      )}
      <div className="w-100 d-flex justify-content-center">
        <ProfitRoverPrimaryButton onClick={saveChanges} disabled={!isDirty || isLoadingUpdate}>
          Save Changes
        </ProfitRoverPrimaryButton>
      </div>
      {error && (
        <div className="mt-4">
          <p style={{ fontSize: '14px', color: ERROR }}>There was an error saving your preferences. Try again later.</p>
        </div>
      )}
    </ProfitRoverCard>
  );
};

export default CommunicationPreferences;
