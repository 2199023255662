import { DataType, Industry } from '../../data-center/datasetConfig/DatasetConfigFormConstants';
import { DataSource } from '../../dataSources/dataSourceConstants';
import { ConfigFieldName } from '../../dataSources/fieldBuilders';
import { HOUR_DIMENSION_TYPE, USER_DIMENSION_TYPE } from '../../workflow/workflowConstants';

export const PARTNER_DATATYPE_NO_REPLACE = {
  [DataSource.GOTAB]: {
    [DataType.TRAN]: true,
    [DataType.GRATUITY]: true,
    [DataType.CATALOG]: false,
  },
  [DataSource.HEARTLAND_RETAIL]: {
    [DataType.TRAN]: true,
    [DataType.CATALOG]: false,
  },
  [DataSource.LIGHTSPEED_K]: {
    [DataType.TRAN]: true,
    [DataType.GRATUITY]: true,
    [DataType.CATALOG]: false,
  },
  [DataSource.SQUARE]: {
    [DataType.TRAN]: true,
    [DataType.GRATUITY]: true,
    [DataType.CATALOG]: false,
  },
  [DataSource.SHOPIFY]: {
    [DataType.TRAN]: true,
  },
};

export const PARTNER_FIELDS = {
  [DataSource.GOTAB]: {
    [DataType.TRAN]: [
      { dataset_field_name: ConfigFieldName.PRICE_PER_UNIT, from_column: 'unitPrice' },
      { dataset_field_name: ConfigFieldName.ITEM_QUANTITY, from_column: 'quantity' },
      { dataset_field_name: ConfigFieldName.TRANSACTION_DATE, from_column: 'created' },
    ],
    [DataType.GRATUITY]: [
      { dataset_field_name: ConfigFieldName.TRANSACTION_DATE, from_column: 'created' },
      { dataset_field_name: ConfigFieldName.REVENUE, from_column: 'tippedSubtotal' },
      { dataset_field_name: ConfigFieldName.GRATUITY, from_column: 'tipAmount' },
    ],
    [DataType.CATALOG]: [
      { dataset_field_name: ConfigFieldName.TRANSACTION_DATE, from_column: 'created' },
      { dataset_field_name: ConfigFieldName.ITEM_QUANTITY, from_column: 'quantity' },
      { dataset_field_name: ConfigFieldName.PRICE_PER_UNIT, from_column: 'price' },
      { dataset_field_name: ConfigFieldName.COST_PER_UNIT, from_column: 'cost' },
    ],
  },
  [DataSource.HEARTLAND_RETAIL]: {
    [DataType.TRAN]: [
      { dataset_field_name: ConfigFieldName.INFO_FIELD, from_column: 'item_public_id' },
      { dataset_field_name: ConfigFieldName.TRANSACTION_DATE, from_column: 'date_hour' },
      { dataset_field_name: ConfigFieldName.ITEM_QUANTITY, from_column: 'net_qty_sold' },
      { dataset_field_name: ConfigFieldName.TOTAL_TRANSACTION_PRICE, from_column: 'net_sales' },
    ],
    [DataType.CATALOG]: [
      { dataset_field_name: ConfigFieldName.INFO_FIELD, from_column: 'public_id' },
      { dataset_field_name: ConfigFieldName.INFO_FIELD, from_column: 'id' },
      { dataset_field_name: ConfigFieldName.INFO_FIELD, from_column: 'active?' },
      { dataset_field_name: ConfigFieldName.INFO_FIELD, from_column: 'primary_barcode' },
      { dataset_field_name: ConfigFieldName.TRANSACTION_DATE, from_column: 'updated_at' },
      { dataset_field_name: ConfigFieldName.ITEM_QUANTITY, from_column: 'qty' },
      { dataset_field_name: ConfigFieldName.PRICE_PER_UNIT, from_column: 'price' },
      { dataset_field_name: ConfigFieldName.COST_PER_UNIT, from_column: 'cost' },
    ],
  },
  [DataSource.LIGHTSPEED_K]: {
    [DataType.TRAN]: [
      { dataset_field_name: ConfigFieldName.INFO_FIELD, from_column: 'receiptId' },
      { dataset_field_name: ConfigFieldName.TRANSACTION_DATE, from_column: 'timeClosed' },
      { dataset_field_name: ConfigFieldName.ITEM_QUANTITY, from_column: 'quantity' },
      { dataset_field_name: ConfigFieldName.TOTAL_TRANSACTION_PRICE, from_column: 'totalNetAmountWithoutTax' },
    ],
    [DataType.GRATUITY]: [
      { dataset_field_name: ConfigFieldName.INFO_FIELD, from_column: 'receiptId' },
      { dataset_field_name: ConfigFieldName.TRANSACTION_DATE, from_column: 'timeClosed' },
      { dataset_field_name: ConfigFieldName.REVENUE, from_column: 'totalNetAmountWithoutTax' },
      { dataset_field_name: ConfigFieldName.GRATUITY, from_column: 'tip' },
    ],
    [DataType.CATALOG]: [
      { dataset_field_name: ConfigFieldName.INFO_FIELD, from_column: 'catalog_id' },
      { dataset_field_name: ConfigFieldName.INFO_FIELD, from_column: 'itemType' },
      { dataset_field_name: ConfigFieldName.TRANSACTION_DATE, from_column: 'fetch_date' },
      { dataset_field_name: ConfigFieldName.ITEM_QUANTITY, from_column: 'quantity' },
      { dataset_field_name: ConfigFieldName.PRICE_PER_UNIT, from_column: 'price' },
      { dataset_field_name: ConfigFieldName.COST_PER_UNIT, from_column: 'costPrice' },
    ],
  },
  [DataSource.SQUARE]: {
    [DataType.TRAN]: [
      { dataset_field_name: ConfigFieldName.PRICE_PER_UNIT, from_column: 'price' },
      { dataset_field_name: ConfigFieldName.ITEM_QUANTITY, from_column: 'quantity' },
      { dataset_field_name: ConfigFieldName.TRANSACTION_DATE, from_column: 'tran_date' },
    ],
    [DataType.GRATUITY]: [
      { dataset_field_name: ConfigFieldName.TRANSACTION_DATE, from_column: 'tran_date' },
      { dataset_field_name: ConfigFieldName.REVENUE, from_column: 'total_amt' },
      { dataset_field_name: ConfigFieldName.GRATUITY, from_column: 'gratuity' },
    ],
    [DataType.CATALOG]: [
      { dataset_field_name: ConfigFieldName.TRANSACTION_DATE, from_column: 'inventory_date' },
      { dataset_field_name: ConfigFieldName.INFO_FIELD, from_column: 'sku' },
      { dataset_field_name: ConfigFieldName.ITEM_QUANTITY, from_column: 'quantity' },
      { dataset_field_name: ConfigFieldName.PRICE_PER_UNIT, from_column: 'price' },
      { dataset_field_name: ConfigFieldName.COST_PER_UNIT, from_column: 'cost' },
    ],
  },
  [DataSource.SHOPIFY]: {
    [DataType.TRAN]: [
      { dataset_field_name: ConfigFieldName.LOCATION, from_column: 'location_id' },
      { dataset_field_name: ConfigFieldName.PRICE_PER_UNIT, from_column: 'price' },
      { dataset_field_name: ConfigFieldName.ITEM_QUANTITY, from_column: 'quantity' },
      { dataset_field_name: ConfigFieldName.TRANSACTION_DATE, from_column: 'created_at' },
      { dataset_field_name: ConfigFieldName.DATE_FIELD, from_column: 'processed_at' },
      { dataset_field_name: ConfigFieldName.DATE_FIELD, from_column: 'updated_at' },
      { dataset_field_name: ConfigFieldName.INFO_FIELD, from_column: 'item_id' },
      { dataset_field_name: ConfigFieldName.INFO_FIELD, from_column: 'shopify_product_id' },
      { dataset_field_name: ConfigFieldName.INFO_FIELD, from_column: 'financial_status' },
      { dataset_field_name: ConfigFieldName.INFO_FIELD, from_column: 'order_number' },
      { dataset_field_name: ConfigFieldName.INFO_FIELD, from_column: 'order_id' },
      { dataset_field_name: ConfigFieldName.INFO_FIELD, from_column: 'sku' },
    ],
  },
};

export const PRODUCT_DIMENSION = {
  name: 'Product',
  dimension_type: USER_DIMENSION_TYPE,
};

export const HOUR_DIMENSION = {
  name: 'Hour',
  dimension_type: HOUR_DIMENSION_TYPE,
};

export const PRODUCT_TYPE_DIMENSION = {
  name: 'Product Type',
  dimension_type: USER_DIMENSION_TYPE,
};

export const PRODUCT_CATEGORY_DIMENSION = {
  name: 'Category',
  dimension_type: USER_DIMENSION_TYPE,
};

export const PRODUCT_SUB_CATEGORY_DIMENSION = {
  name: 'Sub Category',
  dimension_type: USER_DIMENSION_TYPE,
};

export const PRODUCT_VARIATION_DIMENSION = {
  name: 'Variation',
  dimension_type: USER_DIMENSION_TYPE,
};

export const PRODUCT_OPTIONS_DIMENSION = {
  name: 'Options',
  dimension_type: USER_DIMENSION_TYPE,
};

// KP-4048 - add this so that multiple datasources can coexist and share dimensions
export const PARTNER_DIM_ALIASES = {
  [PRODUCT_VARIATION_DIMENSION.name]: PRODUCT_OPTIONS_DIMENSION,
  [PRODUCT_OPTIONS_DIMENSION.name]: PRODUCT_VARIATION_DIMENSION,
};

export const PARTNER_DIMS = {
  [DataSource.GOTAB]: [PRODUCT_DIMENSION, PRODUCT_CATEGORY_DIMENSION, PRODUCT_OPTIONS_DIMENSION, HOUR_DIMENSION],
  [DataSource.HEARTLAND_RETAIL]: [PRODUCT_DIMENSION, HOUR_DIMENSION],
  [DataSource.LIGHTSPEED_K]: [PRODUCT_DIMENSION, PRODUCT_CATEGORY_DIMENSION, HOUR_DIMENSION],
  [DataSource.SQUARE]: [
    PRODUCT_DIMENSION,
    PRODUCT_TYPE_DIMENSION,
    PRODUCT_CATEGORY_DIMENSION,
    PRODUCT_SUB_CATEGORY_DIMENSION,
    PRODUCT_VARIATION_DIMENSION,
    HOUR_DIMENSION,
  ],
  [DataSource.SHOPIFY]: [PRODUCT_DIMENSION, PRODUCT_VARIATION_DIMENSION, HOUR_DIMENSION],
};

export const PARTNER_DIMS_DATATYPE = {
  [DataSource.GOTAB]: {
    [DataType.TRAN]: [PRODUCT_DIMENSION, PRODUCT_CATEGORY_DIMENSION, PRODUCT_OPTIONS_DIMENSION, HOUR_DIMENSION],
    [DataType.GRATUITY]: [HOUR_DIMENSION],
    [DataType.CATALOG]: [PRODUCT_DIMENSION, PRODUCT_CATEGORY_DIMENSION],
  },
  [DataSource.HEARTLAND_RETAIL]: {
    [DataType.TRAN]: [PRODUCT_DIMENSION, HOUR_DIMENSION],
    [DataType.CATALOG]: [PRODUCT_DIMENSION],
  },
  [DataSource.LIGHTSPEED_K]: {
    [DataType.TRAN]: [PRODUCT_DIMENSION, PRODUCT_CATEGORY_DIMENSION, HOUR_DIMENSION],
    [DataType.GRATUITY]: [HOUR_DIMENSION],
    [DataType.CATALOG]: [PRODUCT_DIMENSION, PRODUCT_CATEGORY_DIMENSION],
  },
  [DataSource.SQUARE]: {
    [DataType.TRAN]: [
      PRODUCT_DIMENSION,
      PRODUCT_TYPE_DIMENSION,
      PRODUCT_CATEGORY_DIMENSION,
      PRODUCT_SUB_CATEGORY_DIMENSION,
      PRODUCT_VARIATION_DIMENSION,
      HOUR_DIMENSION,
    ],
    [DataType.GRATUITY]: [HOUR_DIMENSION],
    [DataType.CATALOG]: [
      PRODUCT_DIMENSION,
      PRODUCT_CATEGORY_DIMENSION,
      PRODUCT_SUB_CATEGORY_DIMENSION,
      PRODUCT_VARIATION_DIMENSION,
    ],
  },
  [DataSource.SHOPIFY]: {
    [DataType.TRAN]: [PRODUCT_DIMENSION, PRODUCT_VARIATION_DIMENSION, HOUR_DIMENSION],
  },
};

export const PARTNER_DIM_FROM_COLUMN = {
  [DataSource.GOTAB]: {
    [PRODUCT_DIMENSION.name]: 'product_name',
    [PRODUCT_CATEGORY_DIMENSION.name]: 'product_category_name',
    [PRODUCT_OPTIONS_DIMENSION.name]: 'product_option',
    [HOUR_DIMENSION.name]: null,
  },
  [DataSource.HEARTLAND_RETAIL]: {
    [PRODUCT_DIMENSION.name]: 'description',
    [HOUR_DIMENSION.name]: null,
  },
  [DataSource.LIGHTSPEED_K]: {
    [PRODUCT_DIMENSION.name]: 'item_name',
    [PRODUCT_CATEGORY_DIMENSION.name]: 'accountingGroup',
    [HOUR_DIMENSION.name]: null,
  },
  [DataSource.SQUARE]: {
    [PRODUCT_DIMENSION.name]: 'product',
    [PRODUCT_TYPE_DIMENSION.name]: 'item_type',
    [PRODUCT_CATEGORY_DIMENSION.name]: 'category',
    [PRODUCT_SUB_CATEGORY_DIMENSION.name]: 'sub_category',
    [PRODUCT_VARIATION_DIMENSION.name]: 'variation',
    [HOUR_DIMENSION.name]: null,
  },
  [DataSource.SHOPIFY]: {
    [PRODUCT_DIMENSION.name]: 'product',
    [PRODUCT_VARIATION_DIMENSION.name]: 'variant',
    [HOUR_DIMENSION.name]: null,
  },
};

export const PARTNER_CONFIG_NAME = {
  [DataSource.GOTAB]: {
    [DataType.TRAN]: 'Tabs/Orders/Items',
    [DataType.GRATUITY]: 'Tab Gratuity',
    [DataType.CATALOG]: 'Product Catalog',
  },
  [DataSource.HEARTLAND_RETAIL]: {
    [DataType.TRAN]: 'Net Sales',
    [DataType.CATALOG]: 'Items',
  },
  [DataSource.LIGHTSPEED_K]: {
    [DataType.TRAN]: 'Sales',
    [DataType.GRATUITY]: 'Tips',
    [DataType.CATALOG]: 'Items',
  },
  [DataSource.SQUARE]: {
    [DataType.TRAN]: 'Order Items',
    [DataType.GRATUITY]: 'Order Gratuity',
    [DataType.CATALOG]: 'Item Catalog',
  },
  [DataSource.SHOPIFY]: {
    [DataType.TRAN]: 'Order Items',
  },
};

export const INDUSTRY_DATATYPE_HAS_WORKFLOW = {
  [Industry.EO]: {
    [DataType.TRAN]: true,
  },
  [Industry.ANY]: {
    [DataType.GRATUITY]: true,
    [DataType.CATALOG]: false,
  },
};
