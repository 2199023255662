import { Ability } from '@casl/ability';
import React from 'react';
import { useSelector } from 'react-redux';
import { Switch } from 'react-router-dom';
import DashboardRoute from './DashboardRoute';
import PrivateRoute from './PrivateRoute';
import SubscriptionStatusContext from './SubscriptionStatusContext';
import { useSubscriptionStatuses } from './subscriptionStatusHooks';
// import Clover from '../setup/clover/Clover';
import DataCenter from '../data-center/DataCenter';
import DatasetConfigForm from '../data-center/datasetConfig/DatasetConfigForm';
import Welcome from '../welcome/WelcomePage';
import RevenueUpliftDashboardV2 from '../dashboards/eo/revenueUplift/RevenueUpliftDashboardV2';
import EOKaizenAttendance from '../dashboards/eo/eoKaizenAttendance';
import CFBKaizenPrice from '../dashboards/cfb/cfb-kaizen-price/CFBKaizenPrice';
import CFBKaizenExplore from '../dashboards/cfb/cfbKaizenExplore';
import Preferences from '../accountSettings/Preferences';
import CompanySettings from '../accountSettings/companySettings';
import DimensionsSettings from '../accountSettings/dimensionsSettings';
import PersonalSettings from '../accountSettings/personalSettings';
import DimensionValuesSettings from '../accountSettings/dimensionValuesSettings/DimensionValuesSettings';
import ManageDimensionValueMappings from '../accountSettings/manageDimensionValueMappings/ManageDimensionValueMappings';
import OverRideCurrentPrice from '../accountSettings/overRideCurrentPrice';
import PriceRules from '../accountSettings/configure-price-rules/PriceRules';
import CustomEvents from '../accountSettings/custom-events/CustomEvents';
import LocationSettings from '../accountSettings/locationSettings/LocationSettings';
import SubscriptionManagement from '../accountSettings/subscriptionManagement/SubscriptionManagement';
// eslint-disable-next-line max-len
import ShopifySubscriptionManagment from '../accountSettings/subscriptionManagement/shopify/ShopifySubscriptionManagment';
import Invoices from '../accountSettings/subscriptionManagement/Invoices';
import ManagePaymentMethod from '../accountSettings/subscriptionManagement/ManagePaymentMethod';
import ManagePlan from '../accountSettings/subscriptionManagement/ManagePlan';
import ManageShopifyPlan from '../accountSettings/subscriptionManagement/ManageShopifyPlan';
import PayInvoice from '../accountSettings/subscriptionManagement/PayInvoice';
import UserManagement from '../accountSettings/userManagement/UserManagement';
import ImpersonateUser from '../admin/ImpersonateUserPage';
import LoginRedirect from '../auth/LoginRedirect';
import Logout from '../auth/Logout';
import DataSources from '../dataSources/DataSources';
import {
  DataSourceSettingsFromDataSources,
  DataSourceSettingsFromGuidedSetup,
  EditDataSourceSettings,
} from '../dataSources/DataSourceSettings';
import { DataSource, RedirectBehavior } from '../dataSources/dataSourceConstants';
import AnomalyDetectionDashboard from '../dashboards/ad/AnomalyDetectionDashboard';
import PriceElasticityDashboard from '../dashboards/pe/PriceElasticityDashboard';
import GenericLoadingPage from '../generic/GenericLoadingPage';
import Help from '../help/Help';
import IdleTimeContainer from '../idleTimerContainer/idleTimerContainer';
import {
  GuidedSetupFileUploadSuccess,
  GuidedSetupManualFileUpload,
  DataSourceManualFileUpload,
  DataSourceManualFileUploadSuccess,
} from '../setup/modern/ManualFileUpload';
import GuidedSetup from '../setup/modern/GuidedSetup';
import GuidedSetupPartnerInstructions from '../setup/modern/GuidedSetupPartnerInstructions';
import IntegrationFileUpload from '../setup/modern/IntegrationFileUpload';
import { CreateNewDataSource } from '../setup/modern/PointOfSaleSetup';
import PartnerAuthRedirect from '../setup/modern/PartnerAuthorization';
// import CreateWorkflow from '../workflow/CreateWorkflowPage';
import UpdateWorkflow from '../workflow/UpdateWorkflowPage';
import UpdateWorkflowDimensionsPage from '../workflow/UpdateWorkflowDimensionsPage';
import { Objective } from '../workflow/workflowConstants';
import { AbilityContext } from '../../casl/abilityContext';
import { useUserAbility } from '../../data-access/query/permissions';
import PartnerDataSourceInstructions from '../dataSources/PartnerDataSourceInstructions';
import { PATHS } from './routesConstants';

const SquareAuthRedirect = () => <PartnerAuthRedirect partner={DataSource.SQUARE} />;
const SquareAddNew = () => (
  <PartnerDataSourceInstructions partner={DataSource.SQUARE} redirect={RedirectBehavior.ADD_NEW_DATA_SOURCE} />
);
const SquareReconnect = () => (
  <PartnerDataSourceInstructions partner={DataSource.SQUARE} redirect={RedirectBehavior.RECONNECT} />
);

const GoTabAuthRedirect = () => <PartnerAuthRedirect partner={DataSource.GOTAB} />;
const GoTabAddNew = () => (
  <PartnerDataSourceInstructions partner={DataSource.GOTAB} redirect={RedirectBehavior.ADD_NEW_DATA_SOURCE} />
);
const GoTabReconnect = () => (
  <PartnerDataSourceInstructions partner={DataSource.GOTAB} redirect={RedirectBehavior.RECONNECT} />
);

const HeartlandRetailAuthRedirect = () => <PartnerAuthRedirect partner={DataSource.HEARTLAND_RETAIL} />;
const HeartlandRetailAddNew = () => (
  <PartnerDataSourceInstructions
    partner={DataSource.HEARTLAND_RETAIL}
    redirect={RedirectBehavior.ADD_NEW_DATA_SOURCE}
  />
);
const HeartlandRetailReconnect = () => (
  <PartnerDataSourceInstructions partner={DataSource.HEARTLAND_RETAIL} redirect={RedirectBehavior.RECONNECT} />
);

const LightspeedKAuthRedirect = () => <PartnerAuthRedirect partner={DataSource.LIGHTSPEED_K} />;
const LightspeedKAddNew = () => (
  <PartnerDataSourceInstructions partner={DataSource.LIGHTSPEED_K} redirect={RedirectBehavior.ADD_NEW_DATA_SOURCE} />
);
const LightspeedKReconnect = () => (
  <PartnerDataSourceInstructions partner={DataSource.LIGHTSPEED_K} redirect={RedirectBehavior.RECONNECT} />
);

const ProtectedRoutes = () => {
  const isLoadingSession = useSelector(state => state?.auth?.loading ?? true);
  const { data: ability, isLoading: isLoadingAbility } = useUserAbility();
  const subscriptionState = useSubscriptionStatuses();

  if (isLoadingAbility || isLoadingSession) {
    return <GenericLoadingPage />;
  }

  return (
    <AbilityContext.Provider value={ability ?? new Ability()}>
      <SubscriptionStatusContext.Provider value={subscriptionState}>
        <IdleTimeContainer />
        <Switch>
          <PrivateRoute exact path="/login-redirect" component={LoginRedirect} />
          <PrivateRoute exact path="/logout" component={Logout} />
          <PrivateRoute exact path="/welcome" component={Welcome} />

          {/* First-time user Guided Setup flows */}
          <PrivateRoute exact path="/guided-setup" component={GuidedSetup} />
          <PrivateRoute exact path="/guided-setup/manual/configure" component={DataSourceSettingsFromGuidedSetup} />
          <PrivateRoute exact path="/guided-setup/manual/file-upload" component={GuidedSetupManualFileUpload} />
          <PrivateRoute exact path="/:setupFlow/manual/file-upload/:dataSource" component={IntegrationFileUpload} />
          <PrivateRoute
            exact
            path="/guided-setup/manual/file-upload-success"
            component={GuidedSetupFileUploadSuccess}
          />
          <PrivateRoute exact path="/guided-setup/partner" component={GuidedSetupPartnerInstructions} />
          <PrivateRoute exact path="/gotab-redirect" component={GoTabAuthRedirect} />
          <PrivateRoute exact path="/heartland-redirect" component={HeartlandRetailAuthRedirect} />
          <PrivateRoute exact path="/lightspeedk-redirect" component={LightspeedKAuthRedirect} />
          <PrivateRoute exact path="/square-redirect" component={SquareAuthRedirect} />

          {/* Data Sources & Setup/Edit Settings */}
          <PrivateRoute
            exact
            path={PATHS.dataSources}
            component={props => (
              <DashboardRoute objective={Objective.ALL}>
                <DataSources {...props} />
              </DashboardRoute>
            )}
          />
          <PrivateRoute exact path={`${PATHS.dataSources}/add-new`} component={CreateNewDataSource} />
          <PrivateRoute exact path={`${PATHS.dataSources}/:source_name/settings`} component={EditDataSourceSettings} />
          <PrivateRoute
            exact
            path={`${PATHS.dataSources}/add-new/manual/configure`}
            component={DataSourceSettingsFromDataSources}
          />
          <PrivateRoute
            exact
            path={`${PATHS.dataSources}/add-new/manual/file-upload`}
            component={DataSourceManualFileUpload}
          />
          <PrivateRoute
            exact
            path={`${PATHS.dataSources}/add-new/manual/file-upload-success`}
            component={DataSourceManualFileUploadSuccess}
          />
          <PrivateRoute
            exact
            path={`${PATHS.dataSources}/:setupFlow/manual/file-upload/:dataSource`}
            component={IntegrationFileUpload}
          />

          <PrivateRoute exact path={`${PATHS.dataSources}/add-new/gotab`} component={GoTabAddNew} />
          <PrivateRoute exact path={`${PATHS.dataSources}/reconnect/gotab`} component={GoTabReconnect} />
          <PrivateRoute
            exact
            path={`${PATHS.dataSources}/add-new/heartland_retail`}
            component={HeartlandRetailAddNew}
          />
          <PrivateRoute
            exact
            path={`${PATHS.dataSources}/reconnect/heartland_retail`}
            component={HeartlandRetailReconnect}
          />
          <PrivateRoute exact path={`${PATHS.dataSources}/add-new/square`} component={SquareAddNew} />
          <PrivateRoute exact path={`${PATHS.dataSources}/reconnect/square`} component={SquareReconnect} />
          <PrivateRoute exact path={`${PATHS.dataSources}/add-new/lightspeed_k`} component={LightspeedKAddNew} />
          <PrivateRoute exact path={`${PATHS.dataSources}/reconnect/lightspeed_k`} component={LightspeedKReconnect} />

          {/* System Settings */}
          {/* <PrivateRoute exact path="/createWorkflow" component={CreateWorkflow} /> */}
          <PrivateRoute exact path="/updateWorkflow" component={UpdateWorkflow} />
          <PrivateRoute exact path="/updateWorkflowDimension" component={UpdateWorkflowDimensionsPage} />
          <PrivateRoute exact path={PATHS.personal} component={PersonalSettings} />
          <PrivateRoute exact path={PATHS.company} component={CompanySettings} />
          <PrivateRoute exact path="/overrideCurrentPrices" component={OverRideCurrentPrice} />
          <PrivateRoute exact path={PATHS.locations} component={LocationSettings} />
          <PrivateRoute exact path={PATHS.manageDimensions} component={DimensionsSettings} />
          <PrivateRoute exact path={PATHS.manageDimensionValues} component={DimensionValuesSettings} />
          <PrivateRoute exact path={PATHS.dimensionValueMapping} component={ManageDimensionValueMappings} />
          <PrivateRoute exact path={PATHS.preferences} component={Preferences} />
          <PrivateRoute exact path="/help" component={Help} />
          <PrivateRoute exact path="/configure-price-rules" component={PriceRules} />
          <PrivateRoute exact path={PATHS.customEvents} component={CustomEvents} />
          <PrivateRoute exact path={PATHS.users} component={UserManagement} />

          {/* ProfitRover Administrator */}
          <PrivateRoute exact path="/impersonate-user" component={ImpersonateUser} />

          {/* Subscription Management */}
          <PrivateRoute exact path={PATHS.subscription} component={SubscriptionManagement} />
          <PrivateRoute exact path="/invoices" component={Invoices} />
          <PrivateRoute exact path="/pay-invoice/:invoiceId" component={PayInvoice} />
          <PrivateRoute exact path="/manage-plan" component={ManagePlan} />
          <PrivateRoute exact path="/manage-payment-method" component={ManagePaymentMethod} />

          <PrivateRoute exact path={PATHS.shopifySubscription} component={ShopifySubscriptionManagment} />
          <PrivateRoute exact path="/manage-plan/shopify" component={ManageShopifyPlan} />

          {/* Dashboards */}
          <PrivateRoute
            exact
            path="/profitrover/:workflowId"
            component={props => (
              <DashboardRoute objective={Objective.PRICEOPT}>
                <RevenueUpliftDashboardV2 {...props} />
              </DashboardRoute>
            )}
          />
          <PrivateRoute
            exact
            path="/mediakaizenprice/:workflowId"
            component={props => (
              <DashboardRoute objective={Objective.PRICEELASTICITY}>
                <PriceElasticityDashboard {...props} />
              </DashboardRoute>
            )}
          />
          <PrivateRoute exact path="/kaizenanomaly/:workflowId" component={AnomalyDetectionDashboard} />

          {/* @deprecated */}
          <PrivateRoute exact path="/datacenter" component={DataCenter} />
          <PrivateRoute exact path="/dataconfigurations" component={DatasetConfigForm} />
          {/* Clover integration is temporarily disabled per KP-2719
          <PrivateRoute exact path="/clover" component={Clover} /> */}

          {/* Unused/Legacy Dashboards */}
          <PrivateRoute
            exact
            path="/cfbkaizenprice/:workflowId"
            component={props => (
              <DashboardRoute objective={Objective.ATTENDFORECAST}>
                <CFBKaizenPrice {...props} />
              </DashboardRoute>
            )}
          />
          <PrivateRoute
            exact
            path="/cfbkaizenexplore/:workflowId"
            component={props => (
              <DashboardRoute objective={Objective.ATTENDFORECAST}>
                <CFBKaizenExplore {...props} />
              </DashboardRoute>
            )}
          />
          <PrivateRoute
            exact
            path="/eokaizenattendance/:workflowId"
            component={props => (
              <DashboardRoute objective={Objective.ATTENDFORECAST}>
                <EOKaizenAttendance {...props} />
              </DashboardRoute>
            )}
          />
        </Switch>
      </SubscriptionStatusContext.Provider>
    </AbilityContext.Provider>
  );
};

export default ProtectedRoutes;
