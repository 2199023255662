/* eslint-disable import/prefer-default-export */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { useNavigateToPartnerOAuthInitiate } from './PartnerHooks';
import ConnectCheckModal from '../../dataSources/ConnectCheckModal';
import { DataSource, DataSourceDisplayName } from '../../dataSources/dataSourceConstants';
import { ProfitRoverPrimaryButton } from '../../forms/ProfitRoverButtons';
import './partner-instructions.scss';

const PARTNER_NEEDS_CONFIRMATION = Object.freeze([DataSource.HEARTLAND_RETAIL]);

const PARTNER_ADDITIONAL_STEPS_LIST = {
  [DataSource.GOTAB]: [
    `You will be redirected to a GoTab authorization page (note that you may have to verify your GoTab account).`,
    `Press the Authorize button to grant access (do not refresh your browser during this process).`,
  ],
  [DataSource.HEARTLAND_RETAIL]: [
    `Open up a new tab in your browser window and login to your Heartland Retail account.`,
    `You will be redirected to a Heartland Retail page where you will be asked to 
    allow Profitrover to access your data.`,
    `Click on the Allow button to authorize access.`,
  ],
  [DataSource.LIGHTSPEED_K]: [
    `You will be redirected to a Lightspeed authorization page 
    (note that you may have to login to your Lightspeed account).`,
    `Make sure that each of the protected resources listed is toggled ON
    and then press the Authorize button to grant access
    (do not refresh your browser during this process).`,
  ],
  [DataSource.SQUARE]: ['Login with your Square Credentials.', `Allow access to the permissions we request.`],
};

export const PartnerInstructions = ({ partner }) => {
  const initiateOauth = useNavigateToPartnerOAuthInitiate(partner);
  const [showConnectCheckModal, setshowConnectCheckModal] = React.useState(false);

  const handleConnectButtonClick = () => {
    if (PARTNER_NEEDS_CONFIRMATION.includes(partner)) {
      setshowConnectCheckModal(true);
    } else {
      initiateOauth();
    }
  };

  const handleModalCancel = () => {
    setshowConnectCheckModal(false);
  };

  return (
    <>
      <div className="partner-instructions">
        <ol>
          <li>
            The Connect to {DataSourceDisplayName[partner]} button below initiates the authorization process, allowing
            ProfitRover to access to your account.
          </li>
          {PARTNER_ADDITIONAL_STEPS_LIST[partner].map((step, index) => (
            <li key={index}>{step}</li>
          ))}
        </ol>

        <div className="post-integration-instructions">
          <i>You will be automatically redirected back to ProfitRover.</i>
        </div>

        <hr />

        <ConnectCheckModal
          partner={partner}
          show={showConnectCheckModal}
          onOK={initiateOauth}
          onCancel={handleModalCancel}
        />

        <ProfitRoverPrimaryButton onClick={handleConnectButtonClick}>
          <div className="d-flex align-items-center">
            Connect to {DataSourceDisplayName[partner]}
            <FontAwesomeIcon icon={faCaretRight} pull="right" style={{ fontSize: 22, position: 'relative', top: 1 }} />
          </div>
        </ProfitRoverPrimaryButton>
      </div>
    </>
  );
};
