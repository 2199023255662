import React from 'react';
import { Modal } from 'react-bootstrap';
import { DataSourceDisplayName } from './dataSourceConstants';
import { ProfitRoverPrimaryButton, ProfitRoverSecondaryButton } from '../forms/ProfitRoverButtons';
import { ProfitRoverModalFooter, ProfitRoverModalMain } from '../generic/ProfitRoverCard';
import './datasource-modal.scss';

const ConnectCheckModal = ({ partner, show, onCancel, onOK }) => {
  return (
    <Modal show={show} onHide={onCancel} centered>
      <Modal.Header>
        <Modal.Title>Connect to {DataSourceDisplayName[partner]}</Modal.Title>
      </Modal.Header>
      <ProfitRoverModalMain>
        Are you logged in to your {DataSourceDisplayName[partner]} account?
        <br />
        <br />
        If not, please do that now in another tab in this browser window. Then press the OK button.
      </ProfitRoverModalMain>
      <ProfitRoverModalFooter className="confirmation-modal-footer">
        <ProfitRoverPrimaryButton onClick={onOK}>OK</ProfitRoverPrimaryButton>
        <ProfitRoverSecondaryButton onClick={onCancel}>Cancel</ProfitRoverSecondaryButton>
      </ProfitRoverModalFooter>
    </Modal>
  );
};

export default ConnectCheckModal;
