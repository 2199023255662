const moment = require('moment');

export function formatNumber(number) {
  if (Number.isNaN(number)) {
    return '-';
  }

  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function formatCurrency(number, showCents = false, symbol = '$') {
  return `${symbol}${formatNumber(number.toFixed(showCents ? 2 : 0))}`;
}

export function nullSafeFormatCurrency(value) {
  return value != null ? formatCurrency(value, true) : '$XX.XX';
}

export function findThousandMagnitude(number) {
  if (!Number.isFinite(number)) {
    return undefined;
  }
  let magnitude = 0;

  number = Math.abs(number);

  while (Math.round(number) >= 1000) {
    number /= 1000;
    magnitude += 1;
  }

  return magnitude;
}

const DEFAULT_CURRENCY_SYMBOL = '$';
export function compactFormatNumber(
  number,
  {
    formatAsCurrency = false,
    currencySymbol,
    thousandMagnitude = findThousandMagnitude(number),
    fixedDecimalDigits = 2,
    showCents = false,
  } = {},
) {
  let formattedNumber;

  if (thousandMagnitude === 0) {
    if (showCents) {
      formattedNumber = number.toFixed(fixedDecimalDigits);
    } else {
      formattedNumber = number.toFixed(0);
    }
  } else if (thousandMagnitude === 1) {
    number /= 10e2;
    formattedNumber = `${number.toFixed(fixedDecimalDigits)}K`;
  } else if (thousandMagnitude === 2) {
    number /= 10e5;
    formattedNumber = `${number.toFixed(fixedDecimalDigits)}M`;
  } else if (thousandMagnitude === 3) {
    number /= 10e8;
    formattedNumber = `${number.toFixed(fixedDecimalDigits)}B`;
    // If magnitude is greater than 3 but not undefined, just return the number without formatting
  } else if (thousandMagnitude) {
    formattedNumber = number.toFixed(0);
  } else {
    // A non-finite number was provided
    if (Number.isNaN(number)) {
      return '-';
    }

    return '∞';
  }

  if (formatAsCurrency) {
    currencySymbol = currencySymbol ?? DEFAULT_CURRENCY_SYMBOL;
    formattedNumber = `${currencySymbol}${formattedNumber}`;
  }

  return formattedNumber;
}

export function formatPercent(number) {
  if (number === 0 || Number.isNaN(number)) {
    return '-';
  }
  return `${(number > 0 ? '+' : '') + formatNumber(parseFloat(number).toFixed(1))}%`;
}

export function formatPercentage(number) {
  if (Number.isNaN(number)) {
    return '-';
  }
  return `${formatNumber(parseFloat(number).toFixed(0))}%`;
}

export function formatDateTime(date) {
  return moment(date).format('YYYY-MM-DD HH:mm:ss');
}

export function convertUTCDateStringToLocalDateString(dateTimeString) {
  if (dateTimeString) {
    const utcDate = moment(`${dateTimeString}Z`);
    return utcDate.format('M/D/YYYY h:mm:ss A');
  }
  return '-';
}

export const calculateOrdinal = async string => {
  const numberTester = /[0-9]/;

  if (string == null) {
    return -1;
  }

  if (numberTester.test(string)) {
    return parseInt(string, 10);
  }

  const stringBaseValue = 1000;
  let n = 0;
  const i = string.length - 1;
  const stringUpper = string.toUpperCase();

  for (let j = i; j > 0; j--) {
    n += stringUpper.charCodeAt(j) * 26 ** j;
  }

  return n + stringBaseValue;
};

/**
  Converts an object key values into comma delimited strings.
 * 
 * @param {object} $queryParams - An object whose key values are an array of values to be passed as parameters
 *
 * @return {object}  -  Object containing converted key value strings
 * */
export const arrayParamSerialization = queryParams => {
  const params = queryParams;
  Object.keys(params).forEach(param => {
    if (params[param].length > 0) {
      params[param] = params[param].join(',');
    }
  });
  return params;
};

export const REGEX_HOURS = /^$|^(\d{1,2}):?(\d{0,2})?\s?(A|P|AM|PM)?$/;
export const REGEX_12_HOURS = /^(\d{1,2}):(\d{2}) (AM|PM)$/;

/**
 * Converts a time string from 24-hour format (HH:mm) to 12-hour format (h:mm AM/PM).
 *
 * @param {string} time24 - A string representing the time in 24-hour format (e.g., '13:00').
 * @returns {string} - The time formatted in 12-hour format with AM/PM (e.g., '1:00 PM').
 *
 * @example
 * // Returns '12:00 AM'
 * convertTo12HourFormat('00:00');
 *
 * @example
 * // Returns '1:00 PM'
 * convertTo12HourFormat('13:00');
 *
 * @example
 * // Returns '11:00 PM'
 * convertTo12HourFormat('23:00');
 */
export const convertTo12HourFormat = time24 => {
  const [hour, minute] = time24.split(':'); // Split the input into hours and minutes
  let hour12 = parseInt(hour, 10); // Parse the hour as an integer
  const period = hour12 >= 12 ? 'PM' : 'AM'; // Determine AM or PM

  hour12 = hour12 % 12 || 12; // Convert 0 or 12 to 12 for 12-hour format

  return `${hour12}:${minute} ${period}`; // Return the formatted time string
};
