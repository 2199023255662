import React from 'react';
import { Modal } from 'react-bootstrap';
import { DataSourceDisplayName } from './dataSourceConstants';
import { ProfitRoverPrimaryButton, ProfitRoverSecondaryButton } from '../forms/ProfitRoverButtons';
import { ProfitRoverModalFooter, ProfitRoverModalMain } from '../generic/ProfitRoverCard';
import './datasource-modal.scss';

const DisconnectConfirmationModal = ({ partner, show, onClose, onContinue }) => {
  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header>
        <Modal.Title>Disconnect from {DataSourceDisplayName[partner]}</Modal.Title>
      </Modal.Header>
      <ProfitRoverModalMain>
        Are you sure you want to disconnect from {DataSourceDisplayName[partner]}?
      </ProfitRoverModalMain>
      <ProfitRoverModalFooter className="confirmation-modal-footer">
        <ProfitRoverPrimaryButton onClick={onContinue}>Disconnect</ProfitRoverPrimaryButton>
        <ProfitRoverSecondaryButton onClick={onClose}>Cancel</ProfitRoverSecondaryButton>
      </ProfitRoverModalFooter>
    </Modal>
  );
};

export default DisconnectConfirmationModal;
