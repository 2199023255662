import React from 'react';
import ReactDOM from 'react-dom';
import { getShiftEndTime } from './staffingTabUtil';
import { ALL_HOURS_AND_HALVES } from '../../../accountSettings/locationSettings/locationManagementConstants';
import { convertTo12HourFormat, REGEX_12_HOURS, REGEX_HOURS } from '../../../util/format';

const HourEditor = React.forwardRef((props, ref) => {
  const {
    type,
    data: { start, end },
    context: { hoursRange, is24HourFormat },
  } = props;

  const inputRef = React.useRef();

  const initialState = type === 'start' ? start : getShiftEndTime(end, hoursRange);
  const initialStateFormatted = is24HourFormat ? initialState : convertTo12HourFormat(initialState);
  const [currentValue, setCurrentValue] = React.useState(initialStateFormatted);
  const [showOptions, setShowOptions] = React.useState(false);
  const [dropdownPosition, setDropdownPosition] = React.useState({ top: 0, left: 0 });

  const options = React.useMemo(() => {
    return is24HourFormat ? ALL_HOURS_AND_HALVES : ALL_HOURS_AND_HALVES.map(hour => convertTo12HourFormat(hour));
  }, [is24HourFormat]);

  React.useImperativeHandle(ref, () => ({
    getValue: () => {
      if (is24HourFormat) {
        const [hours, minutes = '00'] = currentValue.split(':');
        const formattedHours = hours.padStart(2, '0');
        const formattedMinutes = minutes === '30' ? '30' : '00';
        return `${formattedHours}:${formattedMinutes}`;
      }
      const match = currentValue.match(REGEX_12_HOURS);
      if (match) {
        const hours = parseInt(match[1], 10);
        const minutes = match[2];
        const period = match[3];
        const hours24 = period === 'PM' && hours !== 12 ? hours + 12 : period === 'AM' && hours === 12 ? 0 : hours;
        const formattedHours = hours24.toString().padStart(2, '0');
        return `${formattedHours}:${minutes}`;
      }

      // discard invalid input and return initial value
      return initialStateFormatted;
    },
  }));

  React.useEffect(() => {
    const updatePosition = () => {
      if (inputRef.current) {
        const rect = inputRef.current.getBoundingClientRect();
        setDropdownPosition({
          top: rect.bottom,
          left: rect.left,
        });
      }
    };

    if (showOptions) {
      // update option list position on scroll and resize
      updatePosition();
      window.addEventListener('scroll', updatePosition, true);
      window.addEventListener('resize', updatePosition);
    }

    return () => {
      window.removeEventListener('scroll', updatePosition, true);
      window.removeEventListener('resize', updatePosition);
    };
  }, [showOptions]);

  const handleInputFocus = () => {
    setShowOptions(true);
  };

  const handleChange = e => {
    const { value } = e.target;
    if (REGEX_HOURS.test(value)) {
      setCurrentValue(value);
    }
  };

  React.useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, []);

  const selectOption = option => {
    setCurrentValue(option);
    setShowOptions(false);
  };

  const filteredOptions = options.filter(option => option.toLowerCase().includes(currentValue.toLowerCase()));

  return (
    <div ref={ref} className="staffing-editor">
      <input ref={inputRef} type="text" value={currentValue} onChange={handleChange} onFocus={handleInputFocus} />
      {showOptions &&
        ReactDOM.createPortal(
          <div
            className="hour-options-container"
            style={{
              top: dropdownPosition.top,
              left: dropdownPosition.left,
              width: inputRef.current ? inputRef.current.offsetWidth : 'auto',
            }}
          >
            {filteredOptions.map(option => (
              <button type="button" className="hour-option" key={option} onMouseDown={() => selectOption(option)}>
                {option}
              </button>
            ))}
          </div>,
          document.body,
        )}
    </div>
  );
});

HourEditor.displayName = 'HourEditor';

export default HourEditor;
