const {
  REACT_APP_HEARTLAND_RETAIL_CLIENT_ID: HEARTLAND_RETAIL_CLIENT_ID,
  REACT_APP_HEARTLAND_RETAIL_REDIRECT_URI: HEARTLAND_RETAIL_REDIRECT_URI,
  REACT_APP_HEARTLAND_RETAIL_BASE_URL: HEARTLAND_RETAIL_BASE_URL,
  REACT_APP_HEARTLAND_RETAIL_SCOPES: HEARTLAND_RETAIL_SCOPES,
} = process.env;

const heartlandRetailOauthURL = `${HEARTLAND_RETAIL_BASE_URL}/oauth/authorize`
  .concat('?client_id=')
  .concat(HEARTLAND_RETAIL_CLIENT_ID)
  .concat('&scope=')
  .concat(HEARTLAND_RETAIL_SCOPES.split(' ').join('+'))
  .concat('&redirect_uri=')
  .concat(encodeURIComponent(HEARTLAND_RETAIL_REDIRECT_URI));

export default heartlandRetailOauthURL;
