import classNames from 'classnames';
import { addDays, format, parse, isToday } from 'date-fns';
import _ from 'lodash';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {
  ACTUAL_INTERVAL,
  DEMAND_METRIC,
  GROSS_SALES_PLUS_TIPS_METRIC,
  INCOME_METRIC,
  LAST_MONTH,
  LAST_QUARTER,
  LAST_WEEK,
  TIPS_METRIC,
  YESTERDAY,
} from './atAGlanceTabConstants';
import AtAGlanceTabContext from './atAGlanceTabContext';
import { usePaceSummary, usePerformanceReview } from './atAGlanceTabUtil';
import HorizontalBarPlot from './charts/HorizontalBarChart';
import PaceBarPlot from './charts/PaceBarPlot';
import { FULL_DATE_FORMAT } from './revenueUpliftConstants';
import { DashboardContext } from './revenueUpliftContexts';
import RevenueUpliftSelect from './RevenueUpliftSelect';
import { ProfitRoverCard } from '../../../generic/ProfitRoverCard';
import ProfitRoverTooltip from '../../../generic/ProfitRoverTooltip';
import Measured from '../../../generic/Measured';
import { compactFormatNumber } from '../../../util/format';
import { gaEmitPeriodDropdownClick } from '../../../../google-analytics/atAGlanceTab';
import { ERROR, FONT_BLACK, LIGHT_BLUE, LIGHT_GREEN, SUCCESS_GREEN } from '../../../../colors';

const stringToDate = dateString => parse(dateString, FULL_DATE_FORMAT, new Date());

const PercentDiff = ({ actual, benchmark }) => {
  if (benchmark === 0) {
    return (
      <div className="my-1" style={{ color: FONT_BLACK }}>
        -
      </div>
    );
  }

  const diff = (actual - benchmark) / benchmark;
  const formattedDiff = Math.round(diff * 100);

  if (diff >= 0) {
    return (
      <div className="my-1" style={{ color: SUCCESS_GREEN }}>
        +{formattedDiff}%
      </div>
    );
  }

  // If diff is negative but rounding yields 0, force a negative sign.
  const displayValue = formattedDiff === 0 ? '-0' : formattedDiff;

  return (
    <div className="my-1" style={{ color: ERROR }}>
      {displayValue}%
    </div>
  );
};

const PerformanceReviewGraph = ({ data }) => {
  const { currencySymbol } = React.useContext(DashboardContext);
  const { selectedMetric } = React.useContext(AtAGlanceTabContext);

  if (data.length === 0 || data.every(d => d.value === 0)) {
    return <div className="empty-text">No data for this period</div>;
  }

  const actualValue = data.find(p => p.type === ACTUAL_INTERVAL)?.value;

  return (
    <div className="d-flex w-100 align-items-center graph-container">
      <div className="legend d-flex flex-column align-items-end mb-1">
        {data.map(period => {
          if (period.range) {
            return (
              <ProfitRoverTooltip
                key={period.type}
                shouldDisplayTooltip
                placement="bottom"
                tooltipText={period.range}
                delay={{ show: 200, hide: 100 }}
              >
                <div className="my-1">{period.label}</div>
              </ProfitRoverTooltip>
            );
          }
          return (
            <div key={period.type} className="my-1">
              {period.label}
            </div>
          );
        })}
      </div>
      <div className="flex-fill" style={{ minWidth: 0 }}>
        <Measured>
          {({ height, width }) => (
            <HorizontalBarPlot
              data={data}
              height={height}
              width={width}
              formatAsCurrency={selectedMetric.value !== DEMAND_METRIC}
              currencySymbol={currencySymbol}
            />
          )}
        </Measured>
      </div>
      {!!actualValue && data.length > 1 && (
        <div className="actuals-diff d-flex flex-column justify-content-end align-items-center mb-1">
          <div
            className={classNames('label', {
              'mb-3': data.length < 3,
            })}
          >
            How Did Actuals Stack Up?
          </div>
          {data
            .filter(d => d.type !== ACTUAL_INTERVAL)
            .map(period => (
              <PercentDiff key={period.type} actual={actualValue} benchmark={period.value} />
            ))}
        </div>
      )}
    </div>
  );
};

const RefreshDateWarning = ({ lastRefreshed }) => {
  const lastRefreshedDate = stringToDate(lastRefreshed);
  const showRefreshWarning = !isToday(lastRefreshedDate);

  return showRefreshWarning ? (
    <ProfitRoverTooltip
      placement="top"
      tooltipText={`Last refreshed ${format(lastRefreshedDate, 'MMM d, yyyy')}`}
      delay={{ show: 200, hide: 100 }}
    >
      <div className="ml-2">
        <FontAwesomeIcon icon={faTriangleExclamation} color="orange" />
      </div>
    </ProfitRoverTooltip>
  ) : null;
};

const PerformanceReviewSection = () => {
  const {
    selectedPeriod,
    setSelectedPeriod,
    periodOptions,
    startDate,
    endDate,
    performanceReviewData,
    lastRefreshed,
  } = usePerformanceReview();

  const getPeriodLabel = (start, end) => {
    const initialDate = stringToDate(start);
    const finalDate = stringToDate(end);

    if (selectedPeriod.value === YESTERDAY) {
      return format(initialDate, 'MMMM d');
    }
    if (selectedPeriod.value === LAST_WEEK) {
      return `${format(initialDate, 'MMMM d')} - ${format(finalDate, 'MMMM d')}`;
    }
    if (selectedPeriod.value === LAST_MONTH) {
      return format(initialDate, 'MMMM');
    }
    if (selectedPeriod.value === LAST_QUARTER) {
      return `${format(initialDate, 'MMMM')} - ${format(finalDate, 'MMMM')}`;
    }

    return '';
  };

  const onPeriodChange = period => {
    gaEmitPeriodDropdownClick(period.value);
    setSelectedPeriod(period);
  };

  return (
    <div className="d-flex flex-column" style={{ flex: 1 }}>
      {performanceReviewData.length > 0 && (
        <>
          <div className="d-flex mt-3 mb-2">
            <h5 className="mb-0">Performance Review</h5>
            <RefreshDateWarning lastRefreshed={lastRefreshed} />
          </div>
          <ProfitRoverCard className="performance-review-section p-3" style={{ flex: 1 }}>
            <div className="content">
              <div className="d-flex align-items-center">
                <div style={{ minWidth: 180 }}>
                  <RevenueUpliftSelect
                    options={periodOptions}
                    getOptionLabel={period => period.label}
                    getOptionValue={period => period.value}
                    onChange={onPeriodChange}
                    value={selectedPeriod}
                  />
                </div>
                <div className="time-period-label ml-3">{getPeriodLabel(startDate, endDate)}</div>
              </div>
              <PerformanceReviewGraph data={performanceReviewData} />
            </div>
          </ProfitRoverCard>
        </>
      )}
    </div>
  );
};

const NumberCell = ({ value }) => {
  const { currencySymbol } = React.useContext(DashboardContext);
  const { selectedMetric } = React.useContext(AtAGlanceTabContext);

  const displayValue = compactFormatNumber(value, {
    formatAsCurrency: selectedMetric.value !== DEMAND_METRIC,
    currencySymbol,
  });

  return <div className="cell-number">{displayValue}</div>;
};

const dateFormat = date => format(date, 'MMM d, yyyy');

const StlyCell = ({ value, minMaxDates }) => {
  const { total, stly, stlyStart, stlyEnd } = value;

  const getRangeTooltipText = () => {
    const [minAvailable, maxAvailable] = minMaxDates;

    if (!minAvailable || !maxAvailable) {
      return 'Insufficient data';
    }

    const minAvailableDate = stringToDate(minAvailable);
    const maxAvailableDate = stringToDate(maxAvailable);

    if (minAvailable > stlyStart && maxAvailable >= stlyEnd) {
      return `Insufficient data; earliest available is ${dateFormat(minAvailableDate)}`;
    }
    if (maxAvailable < stlyEnd && minAvailable <= stlyStart) {
      return `Insufficient data; latest available is ${dateFormat(maxAvailableDate)}`;
    }

    return `Insufficient data; available from ${dateFormat(minAvailableDate)} to ${dateFormat(maxAvailableDate)}`;
  };

  if (!stly || !total) {
    return (
      <ProfitRoverTooltip shouldDisplayTooltip placement="bottom" tooltipText={getRangeTooltipText()}>
        <div className="cell-number">N/A</div>
      </ProfitRoverTooltip>
    );
  }

  const diff = (total - stly) / stly;
  const formattedDiff = Math.round(diff * 100);

  if (diff >= 0) {
    return (
      <div className="cell-number" style={{ color: SUCCESS_GREEN }}>
        +{formattedDiff}%
      </div>
    );
  }

  // If diff is negative but rounding yields 0, force a negative sign.
  const displayValue = formattedDiff === 0 ? '-0' : formattedDiff;

  return (
    <div className="cell-number" style={{ color: ERROR }}>
      {displayValue}%
    </div>
  );
};

const getPeriodRange = (start, end, key, includeYear = false) => {
  const startDate = stringToDate(start);
  const year = format(startDate, 'yyyy');

  if (key === 'month') {
    const month = format(startDate, 'MMM');
    return includeYear ? `${month} ${year}` : month;
  }
  if (key === 'quarter') {
    const endDate = stringToDate(end);
    const quarter = `${format(startDate, 'MMM')} - ${format(endDate, 'MMM')}`;
    return includeYear ? `${quarter} ${year}` : quarter;
  }
  if (key === 'year') {
    return year;
  }

  return '';
};

const parseRange = (min, max) => {
  if (min === max) {
    return format(min, 'MMM d');
  }

  return `${format(min, 'MMM d')} - ${format(max, 'MMM d')}`;
};

const PeriodCell = ({ value }) => {
  const { key, start, end } = value;

  const getPeriodLabel = () => {
    switch (key) {
      case 'month':
        return 'Current Month';
      case 'quarter':
        return 'Current Quarter';
      case 'year':
        return 'Current Year';
      default:
        return '';
    }
  };

  return (
    <div className="cell-period">
      <div className="period-label">{getPeriodLabel()}</div>
      <div className="period-value">{getPeriodRange(start, end, key)}</div>
    </div>
  );
};

/*
 * The data is parsed such that all values are scaled within the range of 0 to 1.
 *
 * We need to identify the maximum value to place at 1, and this can be either:
 * - The STLY line if all STLY values are greater than their corresponding total (toDate + fcst)
 * - Otherwise, it is the greatest total
 *
 * For each bar, we need to parse the toDate and fcst values to a number between 0 and 1, relative to the maximum value.
 * If there is no STLY comparisson for a bar, we parse the numbers relative to its total (toDate + fcst).
 */
const getPlotData = (data, today, periods, currencySymbol, selectedMetric) => {
  const valuesWithStly = Object.values(data).filter(v => v.stly > 0);

  const minRelativeToTotal = _.min(valuesWithStly.map(v => (v.toDate + v.fcst > 0 ? v.stly / (v.toDate + v.fcst) : 1)));

  // The stly should be placed at 1 if all STLY values are greater than their corresponding total
  // Otherwise, it is placed relative to the maxTotal
  const stlyRelativeToMax = Math.min(minRelativeToTotal, 1);

  const getPlotValue = key => {
    const value = data[key];
    const period = periods.find(p => p.key === key);

    const minDate = stringToDate(period.start);
    const todayDate = stringToDate(today);
    const yesterdayDate = addDays(todayDate, -1);
    const maxDate = stringToDate(period.end);

    const formatConfig = {
      formatAsCurrency: selectedMetric.value !== DEMAND_METRIC,
      currencySymbol,
    };
    const tooltips = {
      totalTooltip: `${parseRange(minDate, yesterdayDate)} Actuals: ${compactFormatNumber(value.toDate, formatConfig)}`,
      forecastTooltip: `${parseRange(todayDate, maxDate)} Forecast: ${compactFormatNumber(value.fcst, formatConfig)}`,
      stlyTooltip: `${getPeriodRange(period.stlyStart, period.stlyEnd, key, true)} Actuals: ${compactFormatNumber(
        value.stly,
        formatConfig,
      )}`,
    };

    if (value.stly > 0) {
      return {
        toDate: (value.toDate / value.stly) * stlyRelativeToMax,
        fcst: (value.fcst / value.stly) * stlyRelativeToMax,
        stlyLine: true,
        ...tooltips,
      };
    }

    const total = value.toDate + value.fcst;
    return {
      toDate: value.toDate / total,
      fcst: value.fcst / total,
      stlyLine: false,
      ...tooltips,
    };
  };

  return {
    maxValue: 1,
    stlyLineValue: stlyRelativeToMax,
    plotData: Object.keys(data).map(getPlotValue),
  };
};

const PaceSummarySection = () => {
  const { selectedMetric } = React.useContext(AtAGlanceTabContext);
  const { currencySymbol } = React.useContext(DashboardContext);
  const {
    dates,
    revenue,
    demand,
    tips,
    revenuePlusTips,
    minMaxStatsHistoryDates,
    minMaxHistoryDates,
  } = usePaceSummary();

  const getDataByMetric = () => {
    if (selectedMetric.value === DEMAND_METRIC) {
      return demand;
    }
    if (selectedMetric.value === TIPS_METRIC) {
      return tips;
    }
    if (selectedMetric.value === GROSS_SALES_PLUS_TIPS_METRIC) {
      return revenuePlusTips;
    }
    return revenue;
  };

  const getMinMaxDatesByMetric = () => {
    if (selectedMetric.value === DEMAND_METRIC || selectedMetric.value === INCOME_METRIC) {
      return minMaxHistoryDates;
    }
    if (selectedMetric.value === TIPS_METRIC) {
      return minMaxStatsHistoryDates;
    }
    const minDate =
      minMaxStatsHistoryDates[0] < minMaxHistoryDates[0] ? minMaxStatsHistoryDates[0] : minMaxHistoryDates[0];
    const maxDate =
      minMaxStatsHistoryDates[1] > minMaxHistoryDates[1] ? minMaxStatsHistoryDates[1] : minMaxHistoryDates[1];
    return [minDate, maxDate];
  };

  const data = getDataByMetric();
  const minMaxDates = getMinMaxDatesByMetric();

  const rowData = dates.periods.map(period => {
    const values = data[period.key];
    return {
      period: { key: period.key, start: period.start, end: period.end },
      total: values.toDate + values.fcst,
      vsSTLY: {
        total: values.toDate + values.fcst,
        stly: values.stly,
        stlyStart: period.stlyStart,
        stlyEnd: period.stlyEnd,
      },
    };
  });

  const { maxValue, stlyLineValue, plotData } = getPlotData(
    data,
    dates.today,
    dates.periods,
    currencySymbol,
    selectedMetric,
  );

  return (
    <div className="d-flex flex-column" style={{ flex: 1 }}>
      <div className="d-flex mt-3 mb-2">
        <h5 className="mb-0">Pace</h5>
        <RefreshDateWarning lastRefreshed={dates.today} />
      </div>
      <ProfitRoverCard className="pace-section d-flex flex-column p-3">
        <div className="d-flex flex-row w-100 content">
          <div className="d-flex flex-column periods-column">
            {rowData.map(row => (
              <PeriodCell value={row.period} key={row.period.key} />
            ))}
          </div>
          <div className="flex-fill" style={{ minWidth: 0 }}>
            <Measured>
              {({ height, width }) => (
                <PaceBarPlot
                  data={plotData}
                  height={height}
                  width={width}
                  maxValue={maxValue}
                  stlyLineValue={stlyLineValue}
                />
              )}
            </Measured>
          </div>
          <div className="column mr-3 ml-3">
            <p className="label mb-3">
              Fcsted
              <br />
              Total
            </p>
            <div className="values">
              {rowData.map(row => (
                <NumberCell value={row.total} key={row.period.key} />
              ))}
            </div>
          </div>
          <div className="column">
            <p className="label mb-3">vs STLY</p>
            <div className="values">
              {rowData.map(row => (
                <StlyCell value={row.vsSTLY} minMaxDates={minMaxDates} key={row.period.key} />
              ))}
            </div>
          </div>
        </div>
        <div className="legend mt-2">
          <div className="d-flex align-items-center">
            <div className="color-box to-date mr-1" />
            <div className="legend-label">To Date</div>
          </div>
          <div className="d-flex align-items-center">
            <svg viewBox="0 0 12 12" className="color-box mr-1">
              <defs>
                <pattern
                  id="diagonal-hatch"
                  width="2"
                  height="2"
                  patternTransform="rotate(45 0 0)"
                  patternUnits="userSpaceOnUse"
                >
                  <line x1="0" y1="0" x2="0" y2="2" stroke={LIGHT_BLUE} strokeWidth="1.2" fill={LIGHT_BLUE} />
                </pattern>
              </defs>
              <rect className="fcst-remaining" width="12" height="12" style={{ fill: 'url(#diagonal-hatch)' }} />
            </svg>
            <div className="legend-label">Forecast Remaining</div>
          </div>
          <div className="d-flex align-items-center">
            <svg viewBox="0 0 3 20" className="color-line mr-1">
              <defs>
                <pattern id="dashed-line" width="4" height="20">
                  <line x1="0" y1="0" x2="0" y2="20" stroke={LIGHT_GREEN} strokeWidth="4" strokeDasharray="4,3" />
                </pattern>
              </defs>
              <rect className="stly" width="4" height="20" style={{ fill: 'url(#dashed-line)' }} />
            </svg>
            <div className="legend-label">STLY</div>
          </div>
        </div>
      </ProfitRoverCard>
    </div>
  );
};

const HistoricalPeriodsSection = () => {
  return (
    <div className="history-section w-100 pb-2">
      <PerformanceReviewSection />
      <PaceSummarySection />
    </div>
  );
};

export default HistoricalPeriodsSection;
