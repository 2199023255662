import { DataType } from '../data-center/datasetConfig/DatasetConfigFormConstants';

export const RedirectBehavior = {
  GUIDED_SETUP: 'GUIDED_SETUP',
  ADD_NEW_DATA_SOURCE: 'ADD_NEW_DATA_SOURCE',
  RECONNECT: 'RECONNECT',
};

export const DataSource = {
  BOOKEO: 'BOOKEO',
  CLOVER: 'CLOVER',
  ETSY: 'ETSY',
  GOTAB: 'GOTAB',
  HEARTLAND_RETAIL: 'HEARTLAND_RETAIL',
  LIGHTSPEED_K: 'LIGHTSPEED_K',
  MANUAL: 'MANUAL', // aka "from a .csv/xlsx file"
  SHOPIFY: 'SHOPIFY',
  SQUARE: 'SQUARE',
  TOAST: 'TOAST',
};

export const DataSourceDisplayName = {
  BOOKEO: 'Bookeo',
  CLOVER: 'Clover',
  ETSY: 'Etsy',
  GOTAB: 'GoTab',
  HEARTLAND_RETAIL: 'Heartland Retail',
  LIGHTSPEED_K: 'Lightspeed',
  MANUAL: 'Manual',
  SHOPIFY: 'Shopify',
  SQUARE: 'Square',
  TOAST: 'Toast',
};

/**
 * Hidden DataSources are specified here. One or more may be hidden
 */
const { REACT_APP_HIDDEN_DATASOURCES = '' } = process.env;
const hiddenDataSources = REACT_APP_HIDDEN_DATASOURCES.split(' ').join(',');
export const HIDDEN_DATASOURCES = Object.freeze(hiddenDataSources);

/**
 * Oauth datasources are those that do not require themselves to be a payment channel (like Shopify) and behave
 * similarly such that they each have a minimum set of abilities supporting an oauth flow for obtaining credentials.
 */

export const OAUTH_DATASOURCES = Object.freeze([
  DataSource.GOTAB,
  DataSource.HEARTLAND_RETAIL,
  DataSource.LIGHTSPEED_K,
  DataSource.SQUARE,
]);
export const PAYMENT_CHANNEL_DATASOURCES = Object.freeze([DataSource.SHOPIFY]);

/**
 * Certain DataSources can be set up with manual file uploads (indistinguishable from MANUAL setups),
 * but we name their resulting datasets configs on behalf of the user. The values in this map are those
 * special names.
 *
 * Additionally, these names receive special handling in the Data Center for:
 * - displaying image-based labels instead of raw text
 * - preventing the user from creating a duplicate config for the same DataSource
 */
export const DataSourceToConfigName = {
  [DataSource.CLOVER]: 'Clover',
  [DataSource.ETSY]: 'Etsy',
  [DataSource.TOAST]: 'Toast',
};

export const ConfigNameToDataSource = {
  Clover: DataSource.CLOVER,
  Etsy: DataSource.ETSY,
  Toast: DataSource.TOAST,
};

export const PSEUDO_INTEGRATION_DATA_SOURCES = new Set(Object.keys(DataSourceToConfigName));
export const PSEUDO_INTEGRATION_CONFIG_NAMES = new Set(Object.values(DataSourceToConfigName));

/**
 * Data sources in this set can be automatically configured by our system. We expect that eventually this set and the
 * larger set of all 'pseudo integrations' will fully converge to become equal.
 */
export const PSEUDO_INTEGRATION_AUTOMATIC_CONFIG = new Set([DataSource.CLOVER, DataSource.ETSY]);

export const isFileBasedSource = dataSource =>
  dataSource === DataSource.MANUAL || PSEUDO_INTEGRATION_DATA_SOURCES.has(dataSource);

/**
 * These are the headers that we expect to find in a manually uploaded Clover dataset.
 * Note that there are other columns in the exported dataset that we do not currently use.
 */

const CloverColumnToConfigField = {
  'Line Item Date': 'TRAN',
  'Item Name': 'DIM',
  'Per Unit Quantity': 'QTY',
  'Total Revenue': 'TTP',
};

const CLOVER_HEADERS = Object.keys(CloverColumnToConfigField);

/**
 * These are the headers that we expect to find in a manually uploaded Etsy dataset.
 * Note that there are other columns in the exported dataset that we do not currently use.
 *
 */
const EtsyColumnToConfigField = {
  'Sale Date': 'TRAN',
  'Item Name': 'DIM',
  Quantity: 'QTY',
  Price: 'PPU',
};

const ETSY_HEADERS = Object.keys(EtsyColumnToConfigField);

// Collecting all the headers and Column Config Fields in one place

export const DataSourceHeaders = {
  [DataSource.CLOVER]: CLOVER_HEADERS,
  [DataSource.ETSY]: ETSY_HEADERS,
};

export const DataSourceColumnsToConfigFields = {
  [DataSource.CLOVER]: CloverColumnToConfigField,
  [DataSource.ETSY]: EtsyColumnToConfigField,
};

export const isManualDatasetConfig = (datasetConfig, datasets) => {
  const { partner } = datasetConfig;
  const isDBSource = datasets.filter(dataset => dataset.location_url_type === 'DB').length > 0;

  return partner == null && !isDBSource;
};

const ANCILLARY_DATATYPES = Object.freeze([DataType.GRATUITY, DataType.CATALOG]);

export const isAncillaryDataSource = datasetConfig => {
  const { data_type: dataType } = datasetConfig;

  return ANCILLARY_DATATYPES.includes(dataType);
};
