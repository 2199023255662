export const DAY_START_HOUR = '04:00';

// Summary metrics
export const INCOME_METRIC = 'income';
export const TIPS_METRIC = 'tips';
export const SHIFTS_METRIC = 'shifts';
export const HOURS_METRIC = 'hours';
export const INCOME_PER_EMPLOYEE_HOUR_METRIC = 'incomePerEmployeeHour';
export const TIPS_PER_EMPLOYEE_HOUR_METRIC = 'tipsPerEmployeeHour';
export const LABOR_METRIC = 'laborPercent';
export const LABOR_COST_METRIC = 'laborCost';
export const WAGES_PLUS_TIPS_METRIC = 'wagesPlusTipsPerEmployee';

export const ALL_METRICS = [
  INCOME_METRIC,
  TIPS_METRIC,
  SHIFTS_METRIC,
  HOURS_METRIC,
  INCOME_PER_EMPLOYEE_HOUR_METRIC,
  TIPS_PER_EMPLOYEE_HOUR_METRIC,
];
export const NON_TIPS_METRICS = [
  INCOME_METRIC,
  SHIFTS_METRIC,
  HOURS_METRIC,
  INCOME_PER_EMPLOYEE_HOUR_METRIC,
  LABOR_METRIC,
  LABOR_COST_METRIC,
];
export const FORECAST_METRICS = [INCOME_METRIC, TIPS_METRIC];
export const LABOR_METRICS = [LABOR_METRIC, LABOR_COST_METRIC, WAGES_PLUS_TIPS_METRIC];

export const METRIC_TO_LABEL = {
  [INCOME_METRIC]: 'Income',
  [TIPS_METRIC]: 'Tips',
  [SHIFTS_METRIC]: 'Shifts',
  [HOURS_METRIC]: 'Hours',
  [INCOME_PER_EMPLOYEE_HOUR_METRIC]: 'Rev / Emp Hour',
  [TIPS_PER_EMPLOYEE_HOUR_METRIC]: 'Tips / Emp Hour',
  [LABOR_METRIC]: 'Labor %',
  [LABOR_COST_METRIC]: 'Labor Cost',
  [WAGES_PLUS_TIPS_METRIC]: 'Wages + Tips / Emp Hour',
};

export const PERIOD_OPTIONS = {
  NEXT_7_DAYS: 'next_7_days',
  NEXT_14_DAYS: 'next_14_days',
  NEXT_28_DAYS: 'next_28_days',
  THIS_WEEK: 'this_week',
  NEXT_WEEK: 'next_week',
};

export const STAFFING_RECOMMENDATIONS = {
  LABOR_COST: 'labor_cost',
  REVENUE_PER_EMPLOYEE: 'revenue_per_employee',
};

export const STAFFING_RECOMMENDATIONS_LABELS = {
  [STAFFING_RECOMMENDATIONS.REVENUE_PER_EMPLOYEE]: 'Revenue Per Employee Hour Targets',
  [STAFFING_RECOMMENDATIONS.LABOR_COST]: 'Labor Cost as % of Revenue Targets',
};

// Day details metrics
export const TOTAL_INCOME_METRIC = 'totalIncome';
export const TOTAL_TIPS_METRIC = 'totalTips';
export const TOTAL_INCOME_PER_EMPLOYEE_METRIC = 'incomePerEmployee';
export const TIPS_PER_EMPLOYEE_METRIC = 'tipsPerEmployee';
export const TOTAL_HEADCOUNT_METRIC = 'totalHeadcount';
export const LABOR_PERCENT_METRIC = 'laborPercent';
export const TOTAL_LABOR_COST_METRIC = 'totalLaborCost';
export const WAGES_PLUS_TIPS_PER_EMPLOYEE_METRIC = 'wagesPlusTipsPerEmployee';

export const ALL_DAY_DETAILS_METRICS = [
  TOTAL_INCOME_METRIC,
  TOTAL_TIPS_METRIC,
  TOTAL_INCOME_PER_EMPLOYEE_METRIC,
  TIPS_PER_EMPLOYEE_METRIC,
  TOTAL_HEADCOUNT_METRIC,
];
export const NON_TIPS_DAY_DETAILS_METRICS = [
  TOTAL_INCOME_METRIC,
  TOTAL_INCOME_PER_EMPLOYEE_METRIC,
  TOTAL_HEADCOUNT_METRIC,
];
export const DAY_GRAPH_METRICS = [
  TOTAL_INCOME_METRIC,
  TOTAL_TIPS_METRIC,
  TOTAL_HEADCOUNT_METRIC,
  LABOR_PERCENT_METRIC,
  TOTAL_INCOME_PER_EMPLOYEE_METRIC,
  TIPS_PER_EMPLOYEE_METRIC,
  TOTAL_LABOR_COST_METRIC,
  WAGES_PLUS_TIPS_PER_EMPLOYEE_METRIC,
];
export const FORECAST_DAY_DETAILS_METRICS = [TOTAL_INCOME_METRIC, TOTAL_TIPS_METRIC];

export const DAY_DETAILS_METRIC_TO_LABEL = {
  [TOTAL_INCOME_METRIC]: 'Total Income',
  [TOTAL_TIPS_METRIC]: 'Total Tips',
  [TOTAL_INCOME_PER_EMPLOYEE_METRIC]: 'Total Income Per Employee',
  [TIPS_PER_EMPLOYEE_METRIC]: 'Tips Per Employee',
  [TOTAL_HEADCOUNT_METRIC]: 'Total Headcount',
  [LABOR_PERCENT_METRIC]: 'Labor %',
  [TOTAL_LABOR_COST_METRIC]: 'Total Labor Cost',
  [WAGES_PLUS_TIPS_PER_EMPLOYEE_METRIC]: 'Wages + Tips Per Employee',
};

// Staffing roles
export const STAFFING_ROLE_KEYS = {
  STAFFING_ROLE_NAME: 'name',
  MIN_HOURLY_STAFF_DAY: 'min_hourly_staff_day',
  MIN_HOURLY_STAFF_CLOSE: 'min_hourly_staff_close',
  MIN_HOURLY_REVENUE_PER_EMPLOYEE: 'min_hourly_revenue_per_employee',
  MAX_HOURLY_REVENUE_PER_EMPLOYEE: 'max_hourly_revenue_per_employee',
  MIN_HOURLY_TIPS_PER_EMPLOYEE: 'min_hourly_tips_per_employee',
  MAX_HOURLY_TIPS_PER_EMPLOYEE: 'max_hourly_tips_per_employee',
  MIN_SHIFT_LENGTH: 'min_shift_length',
  MAX_SHIFT_LENGTH: 'max_shift_length',
  AVERAGE_HOURLY_WAGES: 'average_hourly_wages',
  MIN_LABOR_COST_PCT: 'min_labor_cost_pct',
  MAX_LABOR_COST_PCT: 'max_labor_cost_pct',
  BASE_RECOMMENDATIONS_ON: 'base_recommendations_on',
  LAST_SELECTED_METRIC: 'last_selected_metric',
};

export const DEFAULT_STAFFING_ROLE = {
  [STAFFING_ROLE_KEYS.STAFFING_ROLE_NAME]: 'Default',
  [STAFFING_ROLE_KEYS.MIN_HOURLY_STAFF_DAY]: 1,
  [STAFFING_ROLE_KEYS.MIN_HOURLY_STAFF_CLOSE]: 1,
  [STAFFING_ROLE_KEYS.MIN_HOURLY_REVENUE_PER_EMPLOYEE]: 0,
  [STAFFING_ROLE_KEYS.MAX_HOURLY_REVENUE_PER_EMPLOYEE]: 500,
  [STAFFING_ROLE_KEYS.MIN_HOURLY_TIPS_PER_EMPLOYEE]: 0,
  [STAFFING_ROLE_KEYS.MAX_HOURLY_TIPS_PER_EMPLOYEE]: 25,
  [STAFFING_ROLE_KEYS.MIN_SHIFT_LENGTH]: 1,
  [STAFFING_ROLE_KEYS.MAX_SHIFT_LENGTH]: 8,
  [STAFFING_ROLE_KEYS.AVERAGE_HOURLY_WAGES]: 10,
  [STAFFING_ROLE_KEYS.MIN_LABOR_COST_PCT]: 15,
  [STAFFING_ROLE_KEYS.MAX_LABOR_COST_PCT]: 30,
  [STAFFING_ROLE_KEYS.BASE_RECOMMENDATIONS_ON]: STAFFING_RECOMMENDATIONS.LABOR_COST,
  [STAFFING_ROLE_KEYS.LAST_SELECTED_METRIC]: TOTAL_INCOME_METRIC,
};

export const MIN_HOURLY_STAFF = 1;
export const MIN_HOURLY_REVENUE_PER_EMPLOYEE = 10;
export const MIN_SHIFT_LENGTH = 1;
export const MAX_SHIFT_LENGTH = 24;
export const MAX_GRAPH_LABOR_PCT = 200;

export const STAFFING_GRAPH_LABELS = {
  FORECAST: 'Forecast',
  STAFFING_PLAN: 'Staffing Plan',
  ROVER_RECS: 'RoverRecs',
  TARGET_MIN: 'Target Min',
  TARGET_MAX: 'Target Max',
  TARGET_MIN_MAX: 'Target Min/Max',
};

// metrics excluded from graph and table when no shift plans available
export const EXCLUDED_METRICS_WITHOUT_SHIFTS = [
  TOTAL_INCOME_PER_EMPLOYEE_METRIC,
  TIPS_PER_EMPLOYEE_METRIC,
  WAGES_PLUS_TIPS_PER_EMPLOYEE_METRIC,
];
