import React from 'react';
import { LogoHeading } from './DataSourceLogos';
import { ProgressBar, TrackedSteps } from './ProgressBar';
import SecureDataBanner from './SecureDataBanner';
import { PageHeader, PageSubHeader } from '../../forms/GenericFormComponents';
import HeaderAwarePage from '../../generic/HeaderAwarePage';
import { ProfitRoverCard } from '../../generic/ProfitRoverCard';
import Header from '../../header/header';
import IntegrateIconSrc from '../../../images/integrate-icon.png';
import './integration-instructions-page.scss';

const IntegrateIcon = props => <img src={IntegrateIconSrc} alt="Integrate Icon" {...props} />;

/**
 * Generic component for providing integration instructions during the Guided Setup or during
 * the creation of a new Data Source.
 *
 * The Back prop is an optional React Component that can be used to render a "go back" UI that applies
 * to the specific semantics of the page that renders <IntegrationInstructionsPage>.
 */
const IntegrationInstructionsPage = ({ children, dataSource, Back }) => {
  if (dataSource == null) {
    throw new Error('Developer Error: <IntegrationInstructionsPage> requires a "dataSource" prop');
  }

  return (
    <HeaderAwarePage className="integration-instructions">
      {Back && <ProgressBar activeStep={TrackedSteps.CONNECT_DATA} />}
      <div className="container py-5">
        <Header />

        {Back && <Back />}

        <PageHeader className="pb-1">
          Connect Your Data Source | (POS) Integration
          <IntegrateIcon style={{ position: 'relative', bottom: 5 }} />
        </PageHeader>

        <SecureDataBanner />

        <PageSubHeader>Please carefully read all of the steps below before starting.</PageSubHeader>

        <ProfitRoverCard>
          <LogoHeading dataSource={dataSource} />

          {children}
        </ProfitRoverCard>
      </div>
    </HeaderAwarePage>
  );
};

export default IntegrationInstructionsPage;
