import React from 'react';
import { ERROR } from '../../colors';
import { ProfitRoverPrimaryButton } from '../forms/ProfitRoverButtons';
import { ProfitRoverCard } from '../generic/ProfitRoverCard';
import {
  DISPLAY_TIME_FORMAT,
  useEditPreferencesMutation,
  USER_PREFERENCES_SUBJECT,
} from '../../data-access/query/preferences';

const DisplayPreferences = ({ data }) => {
  const [timeFormat, setTimeFormat] = React.useState(data.hour_format ?? DISPLAY_TIME_FORMAT.FORMAT_24_HOUR);
  const [isDirty, setIsDirty] = React.useState(false);

  const userId = React.useRef(localStorage.getItem('id')).current;

  const { mutate: editDisplayPreferences, isLoading: isLoadingUpdate, error } = useEditPreferencesMutation(
    userId,
    USER_PREFERENCES_SUBJECT.DISPLAY,
    {
      onSuccess: () => setIsDirty(false),
    },
  );

  const onChange = e => {
    setTimeFormat(e.target.value);
    setIsDirty(true);
  };

  const saveChanges = () => {
    editDisplayPreferences({
      hour_format: timeFormat,
    });
  };

  return (
    <ProfitRoverCard className="settings-card">
      <h4 className="settings-subheader">Time Display</h4>
      <div className="settings-container">
        <h5 className="label">Display time as:</h5>
        <div className="radio-button-container">
          <label>
            <input
              className="item"
              type="radio"
              value={DISPLAY_TIME_FORMAT.FORMAT_24_HOUR}
              checked={timeFormat === DISPLAY_TIME_FORMAT.FORMAT_24_HOUR}
              onChange={onChange}
            />
            24-Hour Format (e.g., 17:00)
          </label>
          <label>
            <input
              className="item"
              type="radio"
              value={DISPLAY_TIME_FORMAT.FORMAT_12_HOUR}
              checked={timeFormat === DISPLAY_TIME_FORMAT.FORMAT_12_HOUR}
              onChange={onChange}
            />
            12-Hour Format (e.g., 5:00 PM)
          </label>
        </div>
      </div>
      <div className="w-100 d-flex justify-content-center">
        <ProfitRoverPrimaryButton onClick={saveChanges} disabled={!isDirty || isLoadingUpdate}>
          Save Changes
        </ProfitRoverPrimaryButton>
      </div>
      {error && (
        <div className="mt-4">
          <p style={{ fontSize: '14px', color: ERROR }}>There was an error saving your preferences. Try again later.</p>
        </div>
      )}
    </ProfitRoverCard>
  );
};

export default DisplayPreferences;
